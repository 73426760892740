import React, { useRef, useEffect } from "react";
import 'style/main/login.css';
import { apiLogin } from "api/utilities/UsersApi";
import {
    mainReducerLoadUser,
    mainReducerLoadUserRights,
    mainReducerLoadSettings,
    mainReducerLoadPasswords,
} from "state/reducers/mainReducer";
import { useDispatch } from "react-redux";
import Icons from 'components/Icons';
import { setBranchGroupId, setBranchId, setUserId } from "utilities/DB";

const LoginComponent = (props) => {

    const formId = "loginForm";
    const dispatch = useDispatch();

    const usernameTB = useRef();
    const passwordTB = useRef();

    const messageBox = props.messageBox;
    const toast = props.toast;

    const usernameTbKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            passwordTB.current.focus();
        }
    }
    const passwordTbKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            login();
        }
    }
    const loginBtnClick = () => {
        login();
    }
    const login = () => {
        const username = usernameTB.current.value;
        const password = passwordTB.current.value;

        if (username === '') {
            toast.current.show("Please Enter Username.", "i");
            usernameTB.current.focus();
        } else if (password === '') {
            toast.current.show("Please Enter Password.", "i");
            passwordTB.current.focus();
        } else {
            apiLogin(username, password)
                .then((result) => {
                    if (result.isExists) {
                        const user = result.user;
                        const userRights = result.userRights;
                        const settings = result.settings;
                        const passwords = result.passwords;


                        setBranchId(user.branchId);
                        setBranchGroupId(user.branchGroupId);
                        setUserId(user.id);


                        dispatch(mainReducerLoadUser(user));
                        dispatch(mainReducerLoadUserRights(userRights));
                        dispatch(mainReducerLoadSettings(settings));
                        dispatch(mainReducerLoadPasswords(passwords));

                        loadMainForm(userRights);
                    } else {
                        toast.current.show("Incorrect username or password!", "e")
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
        }
    }

    const loadMainForm = (userRights) => {
        const sidePanelButtons = ["Dashboard"];

        // Side Panel Buttons
        if (userRights.items)
            sidePanelButtons.push("Items");
        if (userRights.categories)
            sidePanelButtons.push("Categories");
        if (userRights.sales)
            sidePanelButtons.push("Sales");
        if (userRights.purchase)
            sidePanelButtons.push("Purchase");
        if (userRights.accounts)
            sidePanelButtons.push("Accounts");
        if (userRights.warehouse)
            sidePanelButtons.push("Warehouse");
        if (userRights.reports)
            sidePanelButtons.push("Reports");
        if (userRights.utilities)
            sidePanelButtons.push("Utilities");

        props.loadMainForm(sidePanelButtons);
    }

    useEffect(() => {
        usernameTB.current.focus();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId}>
                <div className="loginContainer">
                    <div className="loginHeader">
                        <img src="/icons/statspos_logo.png" alt="" />
                    </div>
                    <div className="loginBody">
                        <img className="mainLogo" src={Icons.logo} alt="" />
                        <p className="welcome">Welcome Back!</p>
                        <p className="business">Let's Start Today's Business.</p>

                        <div className="box">
                            <img className="icon" src="/icons/username.png" alt="" />
                            <input ref={usernameTB} type="text" tabIndex={1} onKeyDown={usernameTbKeydown} />
                        </div>
                        <div className="box">
                            <img className="icon" src="/icons/password.png" alt="" />
                            <input ref={passwordTB} type="password" tabIndex={2} onKeyDown={passwordTbKeydown} />
                        </div>

                        <button className="loginBtn" onClick={loginBtnClick}>Login</button>

                        <img className="graphees" src="/icons/graphees.png" alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginComponent;