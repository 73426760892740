import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import FlatButtonIcons from 'components/UtilitiesFlatButtonIcons';
import SettingsComponent from "components/utilities/SettingsComponent";
import BarcodeLabelsComponent from "./BarcodeLabelsComponent";
import UsersComponent from "./UsersComponent";

const UtilitiesComponent = (props) => {
    const formId = "utilitiesForm";
    const flatButtons = props.flatButtons;

    const activeBtn = useRef(null);
    const mainActiveTab = useRef(null);

    const [activeTab, setActiveTab] = useState(flatButtons.current[0].toLowerCase().replace(" ", ""));
    // eslint-disable-next-line
    const [activeComponent, setActiveComponent] = useState(flatButtons.current[0].toLowerCase().replace(" ", ""));
    const userRights = useSelector(state => state.main.userRights);

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;

    // const flatButtons = [
    //     "Users",
    //     "Settings",
    //     "Barcode Labels",
    // ];

    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "utilities")
            return;

        if (e.ctrlKey && e.key === "1") {
            e.preventDefault();
            if (userRights.users)
                shortKeyClick("Users");
        }
        else if (e.ctrlKey && e.key === "2") {
            e.preventDefault();
            if (userRights.settings)
                shortKeyClick("Settings");
        }
        else if (e.ctrlKey && e.key === "3") {
            e.preventDefault();
            if (userRights.barcodeLabels)
                shortKeyClick("BarcodeLabels");
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        if (activeBtn.current == null) {
            // const btn = document.getElementById('flatBtnBarcodeLabels');
            const btn = document.getElementById(`flatBtn${flatButtons.current[0].replace(" ", "")}`);
            btn.classList.add("flatBtnClicked");

            const img = document.querySelector(`#${btn.id} img`);
            img.setAttribute("src", `${FlatButtonIcons[btn.getAttribute("data-name") + "S"]}`);

            activeBtn.current = btn.id;
        }

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    const flatBtnClick = (e) => {
        changeActiveBtn(e.currentTarget);

        const btnName = e.currentTarget.getAttribute('data-name');
        setActiveComponent(btnName);
        setActiveTab(btnName);
    };

    const changeActiveBtn = (btn) => {
        // Get Old Button
        const oldBtn = document.getElementById(activeBtn.current);
        oldBtn.classList.remove("flatBtnClicked");
        const oldImg = document.querySelector(`#${oldBtn.id} img`);
        oldImg.setAttribute("src", `${FlatButtonIcons[oldBtn.getAttribute("data-name")]}`);

        // Set Button
        btn.classList.add("flatBtnClicked");
        activeBtn.current = btn.id;
        const img = document.querySelector(`#${btn.id} img`);
        img.setAttribute("src", `${FlatButtonIcons[btn.getAttribute("data-name") + "S"]}`);
    }

    const shortKeyClick = (button) => {
        const btn = document.getElementById(`flatBtn${button}`);
        changeActiveBtn(btn);
        const btnName = btn.getAttribute('data-name');
        setActiveComponent(btnName);
        setActiveTab(btnName);
    };

    return (
        <>
            <div id={formId} className="flatBtnBody">
                <div className="topBar"></div>
                <div className="flatBtnTop">
                    {flatButtons.current.map((e, i) => {
                        return (
                            <div id={`flatBtn${e.replace(" ", "")}`} className="flatBtn" key={e} data-name={e.toLowerCase().replace(" ", "")} onClick={flatBtnClick}>
                                <img className="my-auto" src={FlatButtonIcons[e.toLowerCase().replace(" ", "")]} alt="" />
                                <p className="my-auto">{e}</p>
                            </div>
                        );
                    })}
                </div>

                <div className="flatBtnContainer containerWidth">

                    <div>
                        <div className={`${activeTab === "users" ? 'd-block' : 'd-none'}`}>
                            {
                                userRights.users &&
                                <UsersComponent messageBox={messageBox} toast={toast} activeTab={activeTab} mainActiveTab={props.mainActiveTab} />
                            }
                        </div>
                        <div className={`${activeTab === "settings" ? 'd-block' : 'd-none'}`}>
                            {
                                userRights.settings &&
                                <SettingsComponent messageBox={messageBox} toast={toast} activeTab={activeTab} mainActiveTab={props.mainActiveTab} />
                            }
                        </div>
                        <div className={`${activeTab === "barcodelabels" ? 'd-block' : 'd-none'}`}>
                            {
                                userRights.barcodeLabels &&
                                <BarcodeLabelsComponent messageBox={messageBox} toast={toast} activeTab={activeTab} mainActiveTab={props.mainActiveTab} />
                            }
                        </div>
                    </div>


                    {/* {(() => {
                        switch (activeComponent) {
                            case "users":
                                return <UsersComponent messageBox={messageBox} toast={toast} />;
                            case "settings":
                                return <SettingsComponent messageBox={messageBox} toast={toast} />;
                            case "barcodelabels":
                                return <BarcodeLabelsComponent messageBox={messageBox} toast={toast} />;
                            default:
                                return <div></div>;
                        }
                    })()} */}
                </div>
            </div>
        </>
    )
}

export default UtilitiesComponent;