/* #region Imports */
import React, { useRef, useEffect, useState } from "react";
import 'style/accounts/journalEntry.css';
import DateTimePicker from "components/tools/DateTimePicker";
import Table from "components/tools/Table";
import Textbox from "components/tools/Textbox";
import ButtonIcon from "components/tools/ButtonIcon";
import { apiDeleteEntry, apiLoadEntries, apiPassEntry } from "api/accounts/AccountsApi";
import { convertToNumber, entryTypes } from "utilities/Utils";
import AccountsCB from "components/tools/dropdowns/AccountsCB";
import { useSelector } from "react-redux";
/* #endregion */

const JournalEntryComponent = (props) => {

    /* #region Variables */
    const formId = "journalEntryForm";
    const isActive = props.isActive;
    const userRights = useSelector(state => state.main.userRights);
    const passwords = useSelector(state => state.main.passwords);

    const debitAccountCB = useRef();
    const creditAccountCB = useRef();
    const amountTB = useRef();
    const dateTB = useRef();
    const narationTB = useRef();
    const searchFromDateTB = useRef();
    const searchToDateTB = useRef();

    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const [totalEntries, setTotalEntries] = useState(0);

    const columns = useRef([
        { column: 'id', row: "id", sortBy: "number", style: { minWidth: '45px' } },
        { column: 'Naration', row: "naration", sortBy: "string", style: { minWidth: '400px', width: '100%' } },
        { column: 'Amount', row: "amount", sortBy: "number", style: { minWidth: '120px' } },
        { column: 'Date', row: "date", sortBy: "number", style: { minWidth: '150px' } },
        { column: 'User', row: "username", sortBy: "string", style: { minWidth: '150px' } },
    ]);
    /* #endregion */

    /* #region Methods */
    const loadData = () => {
        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadEntries({
            entryType: entryTypes.journal,
            text: '',
            fromDate: searchFromDateTB.current.getText(),
            toDate: searchToDateTB.current.getText(),
        }).then((result) => {
            try {
                if (isActive.current) {
                    setTotalEntries(result.total.totalEntries);
                    tableRef.current.setData([...result.rows]);
                    tableRef.current.setLoading(false);
                }
            } catch (error) {
                if (isActive.current) {
                    tableRef.current.setLoading(false);
                }
            }
        }).catch((err) => {
            if (isActive.current) {
                messageBox.current.show(err.message, "Error", "e");
                tableRef.current.setLoading(false);
            }
        });
    }
    const inserted = useRef(true);
    const passEntry = () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = validation();
        if (isValid) {
            apiPassEntry(getFormData())
                .then((result) => {
                    if (isActive.current) {
                        inserted.current = true;
                        toast.current.show("Entry Posted Successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }
                }).catch((err) => {
                    if (isActive.current) {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    }
                });
        } else {
            inserted.current = true;
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteEntry(Number(row['id']))
                            .then((result) => {
                                if (isActive.current) {
                                    toast.current.show("Entry deleted successfully.", "s");
                                    loadData();
                                    clearTextboxes();
                                }
                            }).catch((err) => {
                                if (isActive.current) {
                                    messageBox.current.show(err.message, "Error", "e");
                                }
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Entry?")) {
                    apiDeleteEntry(Number(row['id']))
                        .then((result) => {
                            if (isActive.current) {
                                toast.current.show("Entry deleted successfully.", "s");
                                loadData();
                                clearTextboxes();
                            }
                        }).catch((err) => {
                            if (isActive.current) {
                                messageBox.current.show(err.message, "Error", "e");
                            }
                        });
                }
            }

        };
    }
    const clearTextboxes = () => {
        debitAccountCB.current.setValue(0);
        creditAccountCB.current.setValue(0);

        amountTB.current.setText("");
        dateTB.current.setText(new Date());
        narationTB.current.setText("");

        amountTB.current.focus();
    }
    const getFormData = () => {
        const entry = {
            debitAccountId: debitAccountCB.current.getValue(),
            creditAccountId: creditAccountCB.current.getValue(),
            amount: convertToNumber(amountTB.current.getText()),
            naration: narationTB.current.getText(),
            date: dateTB.current.getText(),
            entryType: entryTypes.journal,
        }

        return entry;
    }
    const validation = () => {
        if (debitAccountCB.current.getValue() === 0) {
            toast.current.show("Please Select Debit Account", "i");
            return false;
        }
        if (creditAccountCB.current.getValue() === 0) {
            toast.current.show("Please Select Credit Account", "i");
            return false;
        }

        if (convertToNumber(amountTB.current.getText()) === 0) {
            toast.current.show("Please Enter Amount", "i");
            return false;
        }

        return true;
    }
    /* #endregion */

    /* #region Clicks */
    const onPostBtnClick = () => {
        passEntry();
    }
    const onDeleteBtnClick = () => {
        deleteData();
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            passEntry();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    const onAmountTBKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            narationTB.current.focus();
        }
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        // loadData();

        amountTB.current.focus();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>

                {/* Journal */}
                <AccountsCB
                    ref={debitAccountCB} />
                <AccountsCB
                    ref={creditAccountCB} />

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '4px' }}>

                    <Textbox
                        ref={amountTB}
                        label="Amount"
                        tabIndex={100}
                        style={{ flex: 1 }}
                        onKeyDown={onAmountTBKeyDown} />
                    <div className={`${userRights.dateWiseEntry ? "d-block" : "d-none"}`} style={{ flex: '1' }}>
                        <DateTimePicker
                            ref={dateTB}
                            label="Date"
                            time={false}
                            style={{ flex: 1 }}
                            className="defaultMarginLeft" />
                    </div>
                </div>

                <Textbox
                    ref={narationTB}
                    label="Naraion"
                    tabIndex={101}
                />

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 'var(--defaultMargin)' }}>
                    <ButtonIcon
                        label="Post"
                        icon="icons/buttons/save.png"
                        onClick={onPostBtnClick} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2px' }}>

                    <DateTimePicker
                        ref={searchFromDateTB}
                        label="From Date"
                        time={false}
                        style={{ flex: 1 }}
                        onChange={loadData} />

                    <DateTimePicker
                        ref={searchToDateTB}
                        label="To Date"
                        time={false}
                        style={{ flex: 1 }}
                        className="defaultMarginLeft"
                        onChange={loadData} />
                </div>

                <Table
                    ref={tableRef}
                    columns={columns.current}
                    myTable='journalEntryTable'
                    isActive={isActive}
                    onDoubleClick={() => { }}
                    onEnterKeyDown={(data) => { }}
                    editBtn={{ visible: false, onClick: () => { } }}
                    deleteBtn={{ visible: true, onClick: () => { deleteData() } }} />

                <div className="bottom">
                    <div className="tableFooter">
                        <p><b>Total Entries: </b>{totalEntries}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <ButtonIcon
                            label="Delete"
                            icon="icons/buttons/delete.png"
                            onClick={onDeleteBtnClick} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default JournalEntryComponent;