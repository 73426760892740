import { ceil, floor } from "mathjs";
import dateFormat from "dateformat";

export const selectNextElement = (e) => {
    let next = Number(e.target.tabIndex) + 1;
    const nextElement = document.getElementById(`tb${next}`);
    if (nextElement) {
        nextElement.focus();
    }
}

export const selectNextElement2 = (next) => {
    const nextElement = document.getElementById(`tb${next}`);
    if (nextElement) {
        nextElement.focus();
    }
}

export const convertToNumber = (text) => {
    if (text === '')
        return 0;

    if (isNaN(Number(text)))
        return 0;
    else
        return Number(text);
}

export const convertToInt = (text) => {
    if (text === '')
        return 0;

    if (isNaN(Number(text)))
        return 0;
    else
        return parseInt(Number(text));
}
export const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
}
export const isExpression = (expression) => {
    if (expression.includes("+") || expression.includes("-") || expression.includes("*") || expression.includes("/") || expression.includes("%"))
        return true;
    else
        return false;
}

export const getStockPcs = (stock, crtnSize) => {
    if (crtnSize === 0)
        return stock;
    else
        return (stock % crtnSize);
}

export const getStockCrtn = (stock, crtnSize) => {
    if (crtnSize !== 0) {
        if ((stock / crtnSize) < 0)
            return ceil(stock / crtnSize);
        else
            return floor(stock / crtnSize);
    }
    else
        return 0;
}

export const getFromDate = () => {
    const date = new Date();

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    return date;
}

export const getToDate = () => {
    const date = new Date();

    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    return date;
}

export const concatDateTime = (date1, time1) => {
    const date = new Date(date1);
    const time = new Date(time1)

    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(time.getSeconds());

    return date;
}

export const getDiffDays = (date2, date1) => {
    date2 = new Date(dateFormat(date2, "yyyy-mm-dd"));
    date1 = new Date(dateFormat(date1, "yyyy-mm-dd"));

    const days = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
    return days;
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const toDecimal = (number, decimalPlaces = 2) => {
    return Number(Number(number).toFixed(decimalPlaces));
}

export const isFileSizeValid = (imageDialog) => {
    const file = imageDialog.files[0];
    if (file.size >= 1048576)
        return false;
    else
        return true;
}

export const getReportsData = () => {
    return {
        items: [],
        categories: [],
        subCategories: [],
        customers: [],
        vendors: [],
        suppliers: [],
        fixedAccounts: [],
        users: [],
        expenses: [],
        subExpenses: [],
        banks: [],
        subBanks: [],
        accountCategories: [],
        warehouses: [],
    }
}

export const accounts = {
    cash: 1,
    sales: 2,
    salesReturn: 3,
    purchase: 4,
    purchaseReturn: 5,
}

export const accountTypes = {
    fixedAccount: 1,
    customer: 2,
    vendor: 3,
    bank: 4,
    expense: 5,
    supplier: 6,
}

export const entryTypes = {
    receipt: 1,
    payment: 2,
    expense: 3,
    sales: 4,
    salesReturn: 5,
    purchase: 6,
    purchaseReturn: 7,
    journal: 8,
    stock: 9,
}

export const printSizes = {
    thermal: 1,
    a4: 2,
    a5: 3,
}

export const printLanguages = {
    english: 1,
    urdu: 2,
}
