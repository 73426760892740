import customers from 'icons/flatbuttons/normal/customer_accounts.png';
import vendors from 'icons/flatbuttons/normal/vendor_accounts.png';
import suppliers from 'icons/flatbuttons/normal/supplier_accounts.png';
import expenses from 'icons/flatbuttons/normal/expense_accounts.png';
import banks from 'icons/flatbuttons/normal/bank_accounts.png';

import customersS from 'icons/flatbuttons/selected/customer_accounts.png';
import vendorsS from 'icons/flatbuttons/selected/vendor_accounts.png';
import suppliersS from 'icons/flatbuttons/selected/supplier_accounts.png';
import expensesS from 'icons/flatbuttons/selected/expense_accounts.png';
import banksS from 'icons/flatbuttons/selected/bank_accounts.png';

const AccountsFlatButtonIcons = {
    customers,
    vendors,
    suppliers,
    expenses,
    banks,

    customersS,
    vendorsS,
    suppliersS,
    expensesS,
    banksS,
}

export default AccountsFlatButtonIcons;