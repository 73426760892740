import users from 'icons/flatbuttons/normal/users.png';
import settings from 'icons/flatbuttons/normal/settings.png';
import barcodelabels from 'icons/flatbuttons/normal/barcode_labels.png';

import usersS from 'icons/flatbuttons/selected/users.png';
import settingsS from 'icons/flatbuttons/selected/settings.png';
import barcodelabelsS from 'icons/flatbuttons/selected/barcode_labels.png';

const UtilitiesFlatButtonIcons = {
    users,
    settings,
    barcodelabels,

    usersS,
    settingsS,
    barcodelabelsS,
}

export default UtilitiesFlatButtonIcons;