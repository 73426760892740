import React, { useRef, useEffect, useState } from "react";
import 'style/main/dashboard.css';
import { useSelector } from "react-redux";
import Icons from 'components/Icons';
import BranchesCB from "components/tools/dropdowns/BranchesCB";
import BranchGroupsCB from "components/tools/dropdowns/BranchGroupsCB";
import { getBranchGroupId, getBranchId, setBranchGroupId, setBranchId } from "utilities/DB";
import UserComponent from "./UserComponent";
import ComboBox from "components/tools/ComboBox";


const DashboardComponent = (props) => {
    const formId = "dashboardForm";
    const isActive = useRef(true);
    const user = useSelector(state => state.main.user);
    const branchCB = useRef();
    const branchGroupCB = useRef();
    const [activeComponent, setActiveComponent] = useState(null);
    const themeCB = useRef();

    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const themes = useRef([
        { id: 1, name: 'Default' },
        { id: 2, name: 'Red' },
        { id: 3, name: 'Green' },
    ]);

    const isFirstTimeBranch = useRef(true);
    const onBranchChange = () => {
        if (isFirstTimeBranch.current) {
            isFirstTimeBranch.current = false;
            return;
        }

        setBranchId(branchCB.current.getValue());
        props.onBranchChange();
    }
    const isFirstTimeBranchGroup = useRef(true);
    const onBranchGroupChange = () => {
        if (isFirstTimeBranchGroup.current) {
            isFirstTimeBranchGroup.current = false;
            return;
        }

        setBranchGroupId(branchGroupCB.current.getValue());
        props.onBranchChange();
    }
    const showUserForm = () => {
        setActiveComponent('user');
    }
    const onThemeChange = () => {

    }
    useEffect(() => {
        branchCB.current.setValue(getBranchId());
        branchGroupCB.current.setValue(getBranchGroupId());
        // eslint-disable-next-line
    }, []);

    return (
        <>

            {/* Modals */}
            <div>
                {(() => {
                    switch (activeComponent) {
                        case "user":
                            return <UserComponent
                                messageBox={messageBox}
                                passwordBox={passwordBox}
                                toast={toast}
                                isActive={isActive}
                                setActiveComponent={setActiveComponent}
                            />
                        default:
                            return <div></div>;
                    }
                })()}

            </div>

            <div id={formId}>
                <div className="backgroundContainer">
                    <div className="foregroundContainer">
                        <div className="main">
                            <div>
                                <p className="welcome" onClick={showUserForm}>Welcome {user.username}</p>
                                <p className="business">Let's Start Today's Business.</p>
                            </div>
                            <div>
                                <div className={`${user.isMainUser ? "d-block" : 'd-none'}`} style={{ width: '400px' }}>
                                    <BranchesCB
                                        ref={branchCB}
                                        title="Branch"
                                        onChange={onBranchChange} />
                                    <BranchGroupsCB
                                        ref={branchGroupCB}
                                        title="Branch Group"
                                        onChange={onBranchGroupChange} />
                                </div>

                                <ComboBox
                                    ref={themeCB}
                                    label="Theme"
                                    data={themes.current}
                                    onChange={onThemeChange} />

                            </div>
                        </div>
                    </div>
                    <img className="bgImage" src={Icons.dashboard} alt="Not Found" />
                </div>
            </div>
        </>
    )
};

export default DashboardComponent;