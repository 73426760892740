import React, { useEffect, useRef } from "react";
import 'style/main/main.css';
import FlatButtonIcons from 'components/SideBarFlatButtonIcons';

const Sidebar = (props) => {
    // const navigate = useNavigate();

    const activeBtn = useRef(null);

    const flatButtons = useRef(props.flatButtons);

    const onWindowKeyDown = (e) => {
        if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            shortKeyClick("Dashboard");
        }
        else if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === "i") {
            e.preventDefault();
            shortKeyClick("Items");
        }
        else if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === "c") {
            e.preventDefault();
            shortKeyClick("Categories");
        }
        else if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            shortKeyClick("Sales");
        }
        else if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            shortKeyClick("Purchase");
        }
        else if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === "a") {
            e.preventDefault();
            shortKeyClick("Accounts");
        }
        else if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === "r") {
            e.preventDefault();
            shortKeyClick("Reports");
        }
        else if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === "u") {
            e.preventDefault();
            shortKeyClick("Utilities");
        }
    }

    // eslint-disable-next-line
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
    
        if (activeBtn.current == null) {
            const btn = document.getElementById('flatBtnDashboard');
            btn.classList.add("flatBtnClicked");

            const img = document.querySelector(`#${btn.id} img`);
            img.setAttribute("src", `${FlatButtonIcons[btn.getAttribute("data-name") + "S"]}`);

            activeBtn.current = btn.id;
        }

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const flatBtnClick = (e) => {
        changeActiveBtn(e.currentTarget);


        const btnName = e.currentTarget.getAttribute('data-name');
        props.setActiveComponent(btnName);
        props.setActiveTab(btnName);
    };

    const shortKeyClick = (button) => {
        const btn = document.getElementById(`flatBtn${button}`);
        changeActiveBtn(btn);
        const btnName = btn.getAttribute('data-name');
        props.setActiveComponent(btnName);
        props.setActiveTab(btnName);
    };

    const changeActiveBtn = (btn) => {
        // Get Old Button
        const oldBtn = document.getElementById(activeBtn.current);
        oldBtn.classList.remove("flatBtnClicked");
        const oldImg = document.querySelector(`#${oldBtn.id} img`);
        oldImg.setAttribute("src", `${FlatButtonIcons[oldBtn.getAttribute("data-name")]}`);

        // Set Button
        btn.classList.add("flatBtnClicked");
        activeBtn.current = btn.id;
        const img = document.querySelector(`#${btn.id} img`);
        img.setAttribute("src", `${FlatButtonIcons[btn.getAttribute("data-name") + "S"]}`);
    }

    return (
        <>
            <div className="">
                <img className="statsposLogo" src="/icons/statspos_logo.png" alt="" />

                {flatButtons.current.map((e, i) => {
                    return (
                        <div id={`flatBtn${e}`} className="flatBtn" key={e} data-name={e.toLowerCase()} onClick={flatBtnClick}>
                            <img className="my-auto" src={FlatButtonIcons[e.toLowerCase()]} alt="" />
                            <p className="my-auto">{e}</p>
                        </div>
                    );
                })}

            </div>
        </>
    );
};

export default Sidebar;
