import dashboard from 'icons/flatbuttons/normal/dashboard.png';
import items from 'icons/flatbuttons/normal/items.png';
import categories from 'icons/flatbuttons/normal/categories.png';
import sales from 'icons/flatbuttons/normal/sales.png';
import purchase from 'icons/flatbuttons/normal/purchase.png';
import accounts from 'icons/flatbuttons/normal/accounts.png';
import warehouse from 'icons/flatbuttons/normal/warehouse.png';
import reports from 'icons/flatbuttons/normal/reports.png';
import utilities from 'icons/flatbuttons/normal/utilities.png';

import dashboardS from 'icons/flatbuttons/selected/dashboard.png';
import itemsS from 'icons/flatbuttons/selected/items.png';
import categoriesS from 'icons/flatbuttons/selected/categories.png';
import salesS from 'icons/flatbuttons/selected/sales.png';
import purchaseS from 'icons/flatbuttons/selected/purchase.png';
import accountsS from 'icons/flatbuttons/selected/accounts.png';
import warehouseS from 'icons/flatbuttons/selected/warehouse.png';
import reportsS from 'icons/flatbuttons/selected/reports.png';
import utilitiesS from 'icons/flatbuttons/selected/utilities.png';

const SideBarFlatButtonIcons = {
    dashboard,
    items,
    categories,
    sales,
    purchase,
    accounts,
    warehouse,
    reports,
    utilities,

    dashboardS,
    itemsS,
    categoriesS,
    salesS,
    purchaseS,
    accountsS,
    warehouseS,
    reportsS,
    utilitiesS,
}

export default SideBarFlatButtonIcons;